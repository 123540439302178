import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';
export const sit: IPartnerAppSettings = {
  cdnBaseUrl: 'https://premier-cdn-endpoint-sit.azureedge.net/premier-fxp',
  apiBaseUrl: 'https://premier-traffic-manager-sit.trafficmanager.net/',
  apimUrl: 'https://professionalservicesint.microsoft.com/agreementmanagementsit/',
  apimSub: 'e39f11cbac144b01b3c527f817db7756',
  compassOneBaseUrl: 'https://c1int.azurewebsites.net',
  environment: 'sit',
  serviceEndPoints: [
    {
      clientId: '136b9b86-4fa2-476a-9ea5-ffbdbe4f87bc',
      serviceEndPoint: 'https://premier-traffic-manager-sit.trafficmanager.net/api',
    },
  ]
};
