import { IAppService, IStateConfig, IFxpAppContext, IRouteInfo, IPartnerBundle, IServiceEndPoints,ISharedComponentConfig,ISharedComponents } from '@fxp/fxpservices';
import { appSettings } from './environments/common/appSettingsGenerator';
import { PartnerAppRegistrationService } from '@fxp/fxpservices';

export class PremierApplication_routes implements IAppService {
    getRoutes(userContext: IFxpAppContext): IRouteInfo {
        const routecollection: IStateConfig[] = [
          {
            name: 'premier.myqueue',
            url: '/myqueue',
            data: {
              headerName: 'Agreement Management',
              breadcrumbText: 'My Queue',
              pageTitle: 'My Queue',
              style: '',
              partnerTelemetryName: 'Premier'
            },
          },
          {
            name: 'premier.billing-preview',
            url: '/agreements/:id/billing-preview',
            data: {
              headerName: 'Agreement Management',
              breadcrumbText: '',
              pageTitle: 'Billing Preview',
              style: '',
              partnerTelemetryName: 'Premier'
            },
          },
          {
            name: 'premier.audit-trail',
            url: '/agreements/:id/audit-trail/:entityId',
            data: {
              headerName: 'Agreement Management',
              breadcrumbText: 'Audit Trail',
              pageTitle: 'Audit Trail',
              style: '',
              partnerTelemetryName: 'Premier'
            },
          },
          {
            name: 'premier.invoice-preview',
            url: '/agreements/:id/invoice-preview',
            data: {
              headerName: 'Agreement Management',
              breadcrumbText: '',
              pageTitle: 'Invoice Preview',
              style: '',
              partnerTelemetryName: 'Premier'
            },
          },
          {
            name: 'premier.billing-review',
            url: '/agreements/:id/billing-review',
            data: {
              headerName: 'Agreement Management',
              breadcrumbText: '',
              pageTitle: 'Billing Review',
              style: '',
              partnerTelemetryName: 'Premier'
            },
          },
          {
            name: 'premier.agreements',
            url: '/agreements',
            data: {
              headerName: 'Agreement Management',
              breadcrumbText: 'Agreements',
              pageTitle: 'Agreements',
              style: '',
              partnerTelemetryName: 'Premier'
            },
          },
          {
            name: 'premier.newAgreement',
            url: '/agreements/new',
            redirectTo: 'premier.newAgreement.create',
            data: {
              headerName: 'Agreement Management',
              breadcrumbText: 'New Agreement',
              pageTitle: 'New Agreement',
              style: '',
              partnerTelemetryName: 'Premier'
            },
          },
          {
            name: 'premier.newAgreement.create',
            url: '/',
            data: {
              headerName: 'Agreement Management',
              breadcrumbText: 'New Agreement',
              pageTitle: 'New Agreement',
              style: '',
              partnerTelemetryName: 'Premier'
            },
          },
          {
            name: 'premier.editAgreement',
            url: '/agreements/:id',
            redirectTo: 'premier.editAgreement.header',
            data: {
              headerName: 'Agreement Management',
              breadcrumbText: 'Agreements',
              pageTitle: 'Agreements',
              style: '',
              partnerTelemetryName: 'Premier'
            },
          },
          {
            name: 'premier.editAgreement.header',
            url: '/header',
            data: {
              headerName: 'Agreement Management',
              breadcrumbText: 'Agreements',
              pageTitle: 'Agreements',
              style: '',
              partnerTelemetryName: 'Premier'
            },
          },
          {
            name: 'premier.editAgreement.packages',
            url: '/packages',
            data: {
              headerName: 'Agreement Management',
              breadcrumbText: 'Agreements',
              pageTitle: 'Agreements',
              style: '',
              partnerTelemetryName: 'Premier'
            },
          },
          {
            name: 'premier.editAgreement.microsoft-contacts',
            url: '/microsoft-contacts',
            data: {
              headerName: 'Agreement Management',
              breadcrumbText: 'Agreements',
              pageTitle: 'Agreements',
              style: '',
              partnerTelemetryName: 'Premier'
            },
          },
          {
            name: 'premier.editAgreement.customer-contacts',
            url: '/customer-contacts',
            data: {
              headerName: 'Agreement Management',
              breadcrumbText: 'Agreements',
              pageTitle: 'Agreements',
              style: '',
              partnerTelemetryName: 'Premier'
            },
          },
          {
            name: 'premier.editAgreement.payments',
            url: '/payments',
            data: {
              headerName: 'Agreement Management',
              breadcrumbText: 'Agreements',
              pageTitle: 'Agreements',
              style: '',
              partnerTelemetryName: 'Premier'
            },
          },
          {
            name: 'premier.editAgreement.services',
            url: '/services',
            data: {
              headerName: 'Agreement Management',
              breadcrumbText: 'Agreements',
              pageTitle: 'Agreements',
              style: '',
              partnerTelemetryName: 'Premier'
            },
          },
          {
            name: 'premier.agreementsByCustomer',
            url: '/agreements/customer/:tpid',
            data: {
              headerName: 'Agreement Management',
              breadcrumbText: 'Agreements By Customer',
              pageTitle: 'Agreements By Customer',
              style: '',
              partnerTelemetryName: 'Premier'
            },
          },
          {
            name: 'premier.validate',
            url: '/agreements/:id/validate',
            data: {
              headerName: 'Agreement Management',
              breadcrumbText: 'New Validate',
              pageTitle: '',
              style: 'Validate Agreement',
              partnerTelemetryName: 'Premier'
            }
          },
          {
            name: 'premier.billnow',
            url: '/agreements/:id/billnow',
            data: {
              headerName: 'Agreement Management',
              breadcrumbText: 'New BillNow',
              pageTitle: 'Bill Now',
              style: '',
              partnerTelemetryName: 'Premier'
            }
          },
          {
            name: 'premier.addSite',
            url: '/sites/new',
            data: {
              headerName: 'Agreement Management',
              breadcrumbText: 'New Site',
              pageTitle: 'Add Site',
              style: '',
              partnerTelemetryName: 'Premier'
            },
          },
          {
            name: 'premier.editSite',
            url: '/sites/:id',
            data: {
              headerName: 'Agreement Management',
              breadcrumbText: 'Edit Site',
              pageTitle: '',
              style: '',
              partnerTelemetryName: 'Premier'
            },
          },
          {
            name: 'premier.sites',
            url: '/sites',
            data: {
              headerName: 'Agreement Management',
              breadcrumbText: 'Sites',
              pageTitle: 'Sites',
              style: '',
              partnerTelemetryName: 'Premier'
            }
          },
          {
            name: 'premier.addContact',
            url: '/contacts/new',
            data: {
              headerName: 'Agreement Management',
              breadcrumbText: 'Add Contact',
              pageTitle: 'Add Contact',
              style: '',
              partnerTelemetryName: 'Premier'
            }
          },
          {
            name: 'premier.editContact',
            url: '/contacts/:id',
            data: {
              headerName: 'Agreement Management',
              breadcrumbText: 'Edit Contact',
              pageTitle: 'Edit Contact',
              style: '',
              partnerTelemetryName: 'Premier'
            }
          },
          {
            name: 'premier.contacts',
            url: '/contacts',
            data: {
              headerName: 'Agreement Management',
              breadcrumbText: 'Contacts',
              pageTitle: 'Contacts',
              style: '',
              partnerTelemetryName: 'Premier'
            }
          },
          {
            name: 'premier.newGlobalAgreement',
            url: '/globalagreements/new',
            data: {
              headerName: 'Agreement Management',
              breadcrumbText: 'New Global Agreement',
              pageTitle: 'New Global Agreement',
              style: '',
              partnerTelemetryName: 'Premier'
            }
          },
          {
            name: 'premier.editGlobalAgreement',
            url: '/globalagreements/:id',
            data: {
              headerName: 'Agreement Management',
              breadcrumbText: 'Edit Global Agreement',
              pageTitle: 'Edit Global Agreement',
              style: '',
              partnerTelemetryName: 'Premier'
            }
          },
          {
            name: 'premier.globalAgreements',
            url: '/globalagreements',
            data: {
              headerName: 'Agreement Management',
              breadcrumbText: 'Global Agreements',
              pageTitle: 'Global Agreements',
              style: '',
              partnerTelemetryName: 'Premier'
            }
          },
          {
            name: 'premier.packagesAndLineItems',
            url: '/cab/:id',
            data: {
              headerName: 'Agreement Management',
              breadcrumbText: 'Packages And Line Items',
              pageTitle: 'Packages And Line Items',
              style: '',
              partnerTelemetryName: 'Premier'
            }
          },
          {
            name: 'premier.billingstatusdashboard',
            url: '/billingstatusdashboard',
            data: {
              headerName: 'Agreement Management',
              breadcrumbText: 'Billing Status',
              pageTitle: 'Billing Status',
              style: '',
              partnerTelemetryName: 'Premier'
            },
          },
          {
            name: 'premier.skuswaps',
            url: '/skuswaps',
            data: {
              headerName: 'Agreement Management',
              breadcrumbText: 'SKU Swap',
              pageTitle: 'SKU Swap',
              style: '',
              partnerTelemetryName: 'Premier'
            },
          },
            {
            name: 'premier.reporting',
            url: '/reporting',
            data: {
              headerName: 'Agreement Management',
              breadcrumbText: 'Agreement Management Report',
              pageTitle: 'Agreement Management Report',
              style: '',
              partnerTelemetryName: 'Premier'
            },
          },
          {
            name: 'premier.reportingDashboard',
            url: '/reportingdashboard',
            data: {
              headerName: 'Agreement Management',
              breadcrumbText: 'Agreement Management Report',
              pageTitle: 'Agreement Management Report',
              style: '',
              partnerTelemetryName: 'Premier'
            },
          },
          {
            name: 'premier.editPowerbiComponent',
            url: '/powerbi/:id',
            data: {
              headerName: 'Agreement Management',
              breadcrumbText: 'Agreement Management PowerBI Report',
              pageTitle: 'Agreement Management PowerBI Report',
              style: '',
              partnerTelemetryName: 'Premier'
            },
          },
          {
            name: 'premier',
            url: '/premier',
            redirectTo: 'premier.myqueue',
            data: {
              headerName: 'Agreement Management',
              breadcrumbText: '',
              style: '',
              partnerTelemetryName: 'Premier'
            }
          },
          {
            name: 'premier.contactactions',
            url: '/contactactions',
            data: {
              headerName: 'Agreement Management',
              breadcrumbText: 'Bulk  Contact Actions',
              pageTitle: 'Bulk  Contact Actions',
              style: '',
              partnerTelemetryName: 'Premier'
            },
          },
        ];
          return {
            applicationName: 'PremierApplication',
            sharedBundles: ['PremierApplication-Bundle'],
            routes: routecollection
          };
        }

        getBundles(): Array<IPartnerBundle> {
            const baseUrl = appSettings().cdnBaseUrl;
            const bundlesData = [
              {
                name: 'PremierApplication-Bundle',
                files: [
                  baseUrl + '/vendor.bundle.js',
                  baseUrl + '/styles.bundle.js',
                  baseUrl + '/main.bundle.js',
                ],
                sequentialLoading: true,
              }
            ]
            return bundlesData;
        }

        getServiceEndPoints(): IServiceEndPoints[] {
          return appSettings().serviceEndPoints;
        }
      }

PartnerAppRegistrationService.registerLazyLoadedApp(PremierApplication_routes);
